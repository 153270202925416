
/**
 * Module dependencies.
 */

import { ifProp, theme } from 'styled-tools';
import { useBreakpoint } from '@untile/react-components';
import React, {
  ElementType,
  ReactElement,
  ReactNode,
  useEffect,
  useState
} from 'react';

import styled, { css, keyframes } from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  as?: ElementType,
  canTrigger: boolean,
  children: ReactNode,
  className?: string,
  isAnimated: boolean,
  isVisible: boolean,
  options?: {
    distance?: any,
    transitionDelay?: number,
    transitionTime?: number
  }
};

/**
 * `moveUp` keyframes.
 */

const moveUp = (distance?: string) => keyframes`
  from {
    transform: translateY(${distance ?? '20%'});
  }

  to {
    transform: none;
  }
`;

/**
 * `Content` styled component.
 */

const Content = styled.div<{
  canTrigger: boolean,
  distance?: string,
  isAnimated: boolean,
  isVisible: boolean,
  transitionDelay?: number,
  transitionTime?: number
}>`
  ${props => css`
    opacity: 0;
    transition: opacity ${theme('animations.defaultTransition')} ${props?.transitionDelay ?? 0}s;
    will-change: transform;

    ${props.isVisible && props.canTrigger && css`
      animation: ${moveUp(props?.distance)} ${props?.transitionTime ?? 0.5}s cubic-bezier(0, 0.59, 0, 1) ${props?.transitionDelay ?? 0}s;
      opacity: 1;
    `}
  `}

  ${ifProp('isAnimated', css`
    opacity: 1;
    transform: none;
  `, css`
    opacity: 0;
  `)}

  &.${'full-height'} {
    height: 100%;
  }
`;

/**
 * `FadeInUpAnimation` component.
 */

const FadeInUpAnimation = (props: Props): ReactElement => {
  const { children, className, isVisible, options, ...rest } = props;
  const isMobile = useBreakpoint('md', 'max');
  const [canTrigger, setCanTrigger] = useState<boolean>();
  const [isAnimated, setIsAnimated] = useState<boolean>();

  useEffect(() => {
    if (canTrigger === undefined && isVisible) {
      setCanTrigger(true);
      setIsAnimated(true);

      return;
    }

    if (canTrigger && isVisible) {
      setCanTrigger(false);
      setIsAnimated(true);
    }
  }, [isVisible]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    !isMobile ? (
      <Content
        {...rest}
        canTrigger={canTrigger ?? false}
        className={className}
        distance={options?.distance}
        isAnimated={isAnimated}
        isVisible={isVisible}
        transitionDelay={options?.transitionDelay}
        transitionTime={options?.transitionTime}
      >
        {children}
      </Content>
    ) : (
      <>
        {children}
      </>
    )
  );
};

/**
 * Export `FadeInUpAnimation` component.
 */

export default FadeInUpAnimation;
