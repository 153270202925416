
/**
 * Module dependencies.
 */

import { ifProp } from 'styled-tools';
import { useBreakpoint } from '@untile/react-components';
import React, {
  ElementType,
  ReactElement,
  ReactNode,
  useEffect,
  useState
} from 'react';

import styled, { css } from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  as?: ElementType,
  canTrigger: boolean,
  children: ReactNode,
  className?: string,
  isAnimated: boolean,
  isVisible: boolean,
  options?: {
    transitionDelay?: number,
    transitionTime?: number
  }
};

/**
 * `Content` styled component.
 */

const Content = styled.div<{
  canTrigger: boolean,
  distance?: string,
  isAnimated: boolean,
  isVisible: boolean,
  transitionDelay?: number,
  transitionTime?: number
}>`
  ${props => css`
    opacity: 0;
    transition: opacity ${props?.transitionTime ?? 0.5}s ease ${props?.transitionDelay ?? 0}s;

    ${props.isVisible && props.canTrigger && css`
      opacity: 1;
    `}
  `}

  ${ifProp('isAnimated', css`
    opacity: 1;
  `)}
`;

/**
 * `FadeInAnimation` component.
 */

const FadeInAnimation = (props: Props): ReactElement => {
  const { children, isVisible, options, ...rest } = props;
  const isMobile = useBreakpoint('md', 'max');
  const [canTrigger, setCanTrigger] = useState<boolean>();
  const [isAnimated, setIsAnimated] = useState<boolean>();

  useEffect(() => {
    if (canTrigger === undefined && isVisible) {
      setCanTrigger(true);
      setIsAnimated(true);

      return;
    }

    if (canTrigger && isVisible) {
      setCanTrigger(false);
      setIsAnimated(true);
    }
  }, [isVisible]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    !isMobile ? (
      <Content
        {...rest}
        canTrigger={canTrigger ?? false}
        isAnimated={isAnimated}
        isVisible={isVisible}
        transitionDelay={options?.transitionDelay}
        transitionTime={options?.transitionTime}
      >
        {children}
      </Content>
    ) : (
      <>
        {children}
      </>
    )
  );
};

/**
 * Export `FadeInAnimation` component.
 */

export default FadeInAnimation;
