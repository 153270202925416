
/**
 * Module dependencies.
 */

import React, { ReactElement, ReactNode } from 'react';
import ReactFullpage, { FullpageApi, FullpageOptions } from '@fullpage/react-fullpage';
import styled from 'styled-components';

/**
 * Export `FullpageProps` type.
 */

export type FullpageProps = {
  fullpageApi: FullpageApi,
  state: any
};

/**
 * `Props` type.
 */

type Props = {
  children: (options: FullpageProps) => ReactNode,
  options?: FullpageOptions
};

/**
 * Export `Fullpage` component.
 */

export const Fullpage = ({ children, options }: Props): ReactElement => {
  return (
    <ReactFullpage
      licenseKey={process.env.NEXT_PUBLIC_FULLPAGEJS_LICENSE_KEY}
      render={(options: FullpageProps) => {
        return (
          <ReactFullpage.Wrapper>
            {children(options)}
          </ReactFullpage.Wrapper>
        );
      }}
      responsiveSlides
      responsiveWidth={992}
      scrollOverflow
      verticalCentered={false}
      {...options}
    />
  );
};

/**
 * `SectionWrapper` styled component.
 */

const SectionWrapper = styled.div`
  height: 100%;
`;

/**
 * `SectionProps` type.
 */

type SectionProps = {
  children: ReactNode,
  className?: string
};

/**
 * Export `Section` component.
 */

export const Section = ({ children, className }: SectionProps): ReactElement => (
  <section className={`section ${className} fp-auto-height-responsive`}>
    <SectionWrapper>
      {children}
    </SectionWrapper>
  </section>
);
