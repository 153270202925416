
/**
 * Module dependencies.
 */

import {
  Box,
  RawHtml,
  Type,
  media,
  normalizeNewLine,
  units
} from '@untile/react-components';

import { ifProp, switchProp, theme } from 'styled-tools';
import Container from 'src/components/core/layout/container';
import FadeInAnimation from 'src/components/core/animations/fade-in';
import FadeInUpAnimation from 'src/components/core/animations/fade-in-up';
import Image from 'src/components/core/image';
import React, { ReactElement } from 'react';
import styled, { css } from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  canTrigger: boolean,
  description?: string,
  imageUrl: string,
  isAnimated: boolean,
  isReversed?: boolean,
  isVisible: boolean,
  logoHeight?: string,
  logoPosition?: 'bottom' | 'top',
  logoUrl?: string,
  logoWidth?: string,
  pretitle?: string,
  title: string
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  position: relative;
`;

/**
 * `Grid` styled component.
 */

const Grid = styled.div<{ isReversed?: boolean }>`
  ${media.min('md')`
    display: grid;
    grid-template-rows: repeat(2, max-content);
    
    ${ifProp('isReversed', css`
      grid-template-areas:
        '.             imageWrapper'
        'textContainer textContainer';
      grid-template-columns: 44% 56%;
    `, css`
      grid-template-areas:
        'imageWrapper  .'
        'textContainer textContainer';
      grid-template-columns: 56% 44%;
    `)}
  `}
`;

/**
 * `ImageWrapper` styled component.
 */

const ImageWrapper = styled.div`
  overflow: hidden;
  padding-bottom: 100%;
  position: relative;

  ${media.min('ms')`
    padding-bottom: 60%;
  `}

  ${media.min('md')`
    min-height: calc(100vh - ${theme('dimensions.navbarHeight')}px);
    padding-bottom: unset;
  `}
`;

/**
 * `InnerGrid` styled component.
 */

const InnerGrid = styled.div<{ logoPosition?: 'bottom' | 'top' }>`
  display: grid;
  grid-area: content;
  grid-template-rows: repeat(4, max-content);
  hyphens: auto;

  ${media.min('md')`
    grid-template-rows: minmax(118px, max-content) repeat(3, max-content);
  `}
  
  ${switchProp('logoPosition', {
    bottom: css`
      grid-template-areas:
        '.'
        'title'
        'description'
        'logo';
    `,
    top: css`
      grid-template-areas:
        'logo'
        'title'
        'description';
    `
  })}
`;

/**
 * `LogoWrapper` styled component.
 */

const LogoWrapper = styled.div<{ logoPosition?: 'bottom' | 'top' }>`
  grid-area: logo;
  overflow: hidden;
  position: relative;

  ${switchProp('logoPosition', {
    bottom: css`
      padding-top: ${units(6)};
    `,
    top: css`
      margin-bottom: ${units(4)};
    `
  })}
`;

/**
 * `Title` styled component.
 */

const Title = styled(Type.H1).attrs({ as: 'h2' })`
  margin-bottom: ${units(4)};
`;

/**
 * `Pretitle` styled component.
 */

const Pretitle = styled(Type.H2).attrs({ as: 'h3' })`
  margin-bottom: ${units(2)};
`;

/**
 * `Description` styled component.
 */

const Description = styled(Type.Paragraph)`
  text-align: justify;
`;

/**
 * `StyledContainer` styled component.
 */

const StyledContainer = styled(Container)<{ isReversed?: boolean }>`
  ${media.max('md')`
    margin-bottom: ${units(4)};
  `}

  ${media.min('md')`
    display: grid;
    grid-area: textContainer;
    grid-row: 1 / -1;

    ${ifProp('isReversed', css`
      grid-template-areas: 'content .';
      grid-template-columns: 41% 59%;

      ${LogoWrapper},
      ${Pretitle},
      ${Title} {
        padding-right: ${units(3.5)};
      }

      ${Description} {
        padding-right: ${units(11)};
      }
    `, css`
      grid-template-areas: '. content';
      grid-template-columns: 59% 45%;

      ${InnerGrid} {
        justify-items: flex-end;
      }

      ${LogoWrapper},
      ${Pretitle},
      ${Title} {
        padding-left: ${units(3.5)};
        text-align: right;
      }

      ${Description} {
        padding-left: ${units(11)};
      }
    `)}
  `}
`;

/**
 * `TextImageSection` component.
 */

const TextImageSection = (props: Props): ReactElement => {
  const {
    canTrigger,
    description,
    imageUrl,
    isAnimated,
    isReversed,
    isVisible,
    logoHeight,
    logoPosition,
    logoUrl,
    logoWidth,
    pretitle,
    title
  } = props;

  return (
    <Wrapper>
      <Grid isReversed={isReversed}>
        <StyledContainer isReversed={isReversed}>
          <InnerGrid logoPosition={logoPosition ?? 'top'}>
            {logoUrl && (
              <LogoWrapper logoPosition={logoPosition ?? 'top'}>
                <FadeInAnimation
                  canTrigger={canTrigger}
                  isAnimated={isAnimated}
                  isVisible={isVisible}
                  options={{
                    transitionDelay: logoPosition !== 'bottom' ? 0.5 : 1.5,
                    transitionTime: 1
                  }}
                >
                  <Image
                    alt={title}
                    height={logoHeight}
                    layout={'intrinsic'}
                    objectFit={'contain'}
                    src={logoUrl}
                    width={logoWidth}
                  />
                </FadeInAnimation>
              </LogoWrapper>
            )}

            <Box gridArea={'title'}>
              <FadeInUpAnimation
                canTrigger={canTrigger}
                isAnimated={isAnimated}
                isVisible={isVisible}
                options={{
                  distance: '100vh',
                  transitionDelay: logoUrl && logoPosition !== 'bottom' ? 0.75 : 0.5,
                  transitionTime: 1
                }}
              >
                {pretitle && (
                  <Pretitle>
                    {pretitle}
                  </Pretitle>
                )}

                <RawHtml element={Title}>
                  {normalizeNewLine(title)}
                </RawHtml>
              </FadeInUpAnimation>
            </Box>

            <Box gridArea={'description'}>
              <FadeInUpAnimation
                canTrigger={canTrigger}
                isAnimated={isAnimated}
                isVisible={isVisible}
                options={{
                  distance: '100vh',
                  transitionDelay: logoUrl && logoPosition !== 'bottom' ? 1.25 : 1,
                  transitionTime: 1
                }}
              >
                <RawHtml element={Description}>
                  {normalizeNewLine(description)}
                </RawHtml>
              </FadeInUpAnimation>
            </Box>
          </InnerGrid>
        </StyledContainer>

        <Box
          gridAreaMd={'imageWrapper'}
          gridRowMd={'1 / -1'}
        >
          <FadeInUpAnimation
            canTrigger={canTrigger}
            isAnimated={isAnimated}
            isVisible={isVisible}
            options={{
              distance: '100%',
              transitionDelay: logoUrl && logoPosition !== 'bottom' ? 1 : 0.75,
              transitionTime: 1
            }}
          >
            <ImageWrapper>
              <Image
                alt={title}
                layout={'fill'}
                objectFit={'cover'}
                src={imageUrl}
              />
            </ImageWrapper>
          </FadeInUpAnimation>
        </Box>
      </Grid>
    </Wrapper>
  );
};

/**
 * Export `TextImageSection` component.
 */

export default TextImageSection;
